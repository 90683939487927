import { APP_LAYOUT_MAIN_CONTAINER_ID } from "@/app/_components/AppPortalLayout/constants"
import { type ThemeOptions, getContrastRatio, lighten } from "ui/dist/styles"
import tokenBreakpoints from "./tokens/breakpoints"
import {
  black,
  error,
  info,
  light,
  dark,
  primary,
  secondary,
  success,
  warning,
  white,
} from "./tokens/colors"
import type { CreateThemeOptions } from "./types"

/**
 * here we initialize the mui theme based on our framework agnostic design tokens
 * the same tokens are used in tailwind.config.js
 */

const breakpoints: ThemeOptions["breakpoints"] = {
  values: tokenBreakpoints,
}

const typography: ThemeOptions["typography"] = {
  fontFamily: "var(--font-roboto)",
  tableTitle: {
    fontSize: "16px",
    letterSpacing: "0.17 px",
    fontWeight: 500,
    lineHeight: "24px",
  },
  captionTiny: {
    fontSize: "10px",
  },
}

const zIndex: ThemeOptions["zIndex"] = {
  modal: 1405,
}

const components: ThemeOptions["components"] = {
  MuiDialog: {
    /**
     * - these settings allow us to render the dialog in the main content area, without covering the aside view
     * - disableEnforceFocus is important to allow editing the aside content while the dialog is open
     * - the APP_LAYOUT_MAIN_CONTAINER_ID is only available in the AppPortalLayout component, everywhere else (e.g. POA page) the dialog will be rendered in the body
     */
    defaultProps: {
      disableEnforceFocus: true,
      container: () => document.getElementById(APP_LAYOUT_MAIN_CONTAINER_ID),
      slotProps: {
        root: {
          style: {
            // important to ensure the dialog gets positioned relative to the main content
            position: "absolute",
          },
        },
        backdrop: {
          style: {
            // important to ensure the backdrop gets positioned relative to the main content
            position: "absolute",
          },
        },
      },
    },
  },
  MuiContainer: {
    defaultProps: {
      maxWidth: "xl",
    },
    styleOverrides: {},
  },
  MuiButton: {
    defaultProps: {},
    styleOverrides: {
      root: {
        textTransform: "none",
        fontWeight: 500,
      },
    },
  },
  MuiToggleButton: {
    styleOverrides: {
      root: {
        textTransform: "none",
        fontWeight: 500,
      },
    },
  },
  MuiAutocomplete: {
    defaultProps: {
      limitTags: 2,
    },
    styleOverrides: {},
  },
  MuiTab: {
    defaultProps: {},
    styleOverrides: {
      root: {
        textTransform: "none",
      },
    },
  },
  MuiTextField: {
    defaultProps: {
      autoComplete: "off",
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        minHeight: "48px", // align height with AppBar
      },
    },
  },
}

export const muiTheme: CreateThemeOptions = {
  colorSchemes: {
    light: {
      palette: {
        primary: { main: primary },
        secondary: { main: secondary },
        error: {
          main: error,
          light: lighten(error, 0.25),
          contrastText: getContrastRatio(error, white) >= 2 ? white : black,
        },
        warning: {
          main: warning,
          light: lighten(warning, 0.25),
          contrastText: getContrastRatio(warning, white) >= 2 ? white : black,
        },
        success: {
          main: success,
          light: lighten(success, 0.25),
          contrastText: getContrastRatio(success, white) >= 2 ? white : black,
        },
        info: { main: info },
        background: { default: light.main, paper: light.main },
      },
    },
    dark: {
      palette: {
        primary: { main: primary },
        secondary: { main: secondary },
        error: {
          main: error,
          light: lighten(error, 0.25),
          contrastText: getContrastRatio(error, white) >= 2 ? white : black,
        },
        warning: {
          main: warning,
          light: lighten(warning, 0.25),
          contrastText: getContrastRatio(warning, white) >= 2 ? white : black,
        },
        success: {
          main: success,
          light: lighten(success, 0.25),
          contrastText: getContrastRatio(success, white) >= 2 ? white : black,
        },
        info: { main: info },
        background: { default: dark.off, paper: dark.main },
      },
    },
  },
  cssVariables: { cssVarPrefix: "", colorSchemeSelector: "class" },
  typography,
  zIndex,
  breakpoints,
  components,
}
