export const primary = "#2979FF" as const

export const secondary = "#14a37f" as const

export const error = "#D50000" as const

export const warning = "#FF6D00" as const

export const success = "#00C853" as const

export const info = "#0288d1" as const

export const dark = { main: "#0B0B0B", off: "#151515" } as const
export const light = { main: "#FFFFFF", off: "#EAEAEA" } as const

export const black = "#000000" as const
export const white = "#FFFFFF" as const

const colors = {
  primary,
  secondary,
  dark,
  light,
  error,
  warning,
  success,
  info,
  black,
  white,
} as const

export default colors
