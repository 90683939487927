"use client"

import type { CreateThemeOptions } from "@/theme/types"
import dayjs from "dayjs"
import timezone from "dayjs/plugin/timezone"
import utc from "dayjs/plugin/utc"
import { deepMerge } from "@/utils/object"
import { useMemo } from "react"
import {
  StyledEngineProvider,
  ThemeProvider,
  createTheme,
} from "ui/dist/styles"
import { deDE as coreDeDE } from "ui/dist/utils/locales"
import { deDE } from "ui/dist/utils/locales/x-data-grid"
import { deDE as pickersDeDE } from "ui/dist/utils/locales/x-date-pickers"
import { muiTheme } from "../../../theme/mui.config"
import { CssBaseline } from "ui/dist/provider/CSSBaseLine"
import {
  AdapterDayjs,
  LocalizationProvider,
} from "ui/dist/provider/LocalizationProvider"

/**
 * TODO: import locale dynamically in future, once we support multiple locales
 */
import "dayjs/locale/de"
import RootSnackbarProvider from "../RootSnackbarProvider"

dayjs.extend(utc)
dayjs.extend(timezone)

export interface RootThemeProviderProps {
  children: React.ReactNode
  theme?: CreateThemeOptions
}

export function RootThemeProvider({ children, theme }: RootThemeProviderProps) {
  const updatedTheme = useMemo(() => {
    if (!theme) {
      return createTheme(muiTheme, deDE, pickersDeDE, coreDeDE)
    }
    const newTheme = theme
      ? // @ts-expect-error the CreateThemeOptions type is too complex for the deep merge function
        deepMerge(muiTheme, theme)
      : muiTheme

    return createTheme(newTheme, deDE, pickersDeDE, coreDeDE)
  }, [theme])

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={updatedTheme}>
        <CssBaseline enableColorScheme />
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
          <RootSnackbarProvider>{children}</RootSnackbarProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}
