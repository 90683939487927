import type { DeepPartial } from "@/global"

/**
 * deep merge two objects with the following logic:
 * - if a key exists in new object, but not in the old object, create a new object with the key and the value
 * - if a value of the new obejct is null, overwrite the value of the old object with null
 *
 * this function is a domain specific deep merge function for this reducer so we do not expose it globally
 */
export const deepMerge = <T extends object>(
  oldObject: T,
  ...newObjects: DeepPartial<T>[]
): T => {
  const merged = { ...oldObject }

  for (const newObject of newObjects) {
    for (const key in newObject) {
      const oldValue = oldObject[key]
      const newValue = newObject[key]

      if (newValue === null) {
        // @ts-expect-error we expect this errer, as we need generic superpowers to set the value inside this deep merge
        merged[key] = null
      } else if (typeof newValue === "object") {
        // @ts-expect-error we expect this errer, as we need generic superpowers to set the value inside this deep merge
        merged[key] = deepMerge(oldValue ?? {}, newValue)
      } else {
        // @ts-expect-error we expect this errer, as we need generic superpowers to set the value inside this deep merge
        merged[key] = newValue
      }
    }
  }

  return merged
}
